import React from 'react';
import { CssBaseline, Typography, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  mainMessage: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  subMessage: {
    fontSize: '14px',
    marginTop: theme.spacing(2),
  },
}));

function PageNotAvailable() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <CssBaseline />
      <Typography className={classes.mainMessage}>
        Esta página não está disponível.
      </Typography>
      <Typography className={classes.subMessage}>
        O link em que você clicou pode não estar funcionando, ou a página pode ter sido removida.
      </Typography>
    </Container>
  );
}

export default PageNotAvailable;
